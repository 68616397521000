<template>
    <div class="select flex-column-center">
        <div class="tab flex-center">
            <div
                    :class="{'tab-item':true,'tab-active':active === index}"
                    @click="changeActive(index)"
                    v-for="(item,index) in data.cate"
                    :key="index">
                <span>{{item.name}}</span>
            </div>
            <div class="tab-sub" ref="sub"></div>
        </div>
        <div style="overflow: hidden;width: 100%">
            <div class="tab-con" :style="{transform: `translate3d(-${active*100}%, 0px, 0px)`}">
                <div
                    :class="{'tab-con-item':true,'inactive':active !== index}"
                    v-for="(item,index) in dataKind"
                    :key="index">
                   <van-image width="100%"   :src="values.show_pic" v-for="(values) in item" :key="values.id" @click="href(values.id)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        mounted() {
            let toast = this.$toast.loading({
                message: '加载中',
                forbidClick: true,
                overlay:true
            });
            this.$request.get(
                "products"
            ).then(res => {
                this.data = res.data
                toast.clear()

                this.$nextTick(() => {
                    let select_active = window.sessionStorage.getItem("select_active") || 0
                    this.changeActive(parseInt(select_active))
                })
                console.log(this.dataKind)
            })
        },
        data(){
            return{
                active:0,
                data:{}
            }
        },
        methods:{
            changeActive(index = 0){
                this.active = index
                let selectObj = document.querySelectorAll(".tab-item span")[this.active]
                //写入缓存
                window.sessionStorage.setItem("select_active",index)
                let w = (selectObj.offsetWidth - this.$refs.sub.offsetWidth) / 2
                let offLeft = selectObj?.offsetLeft + w
                this.$refs.sub.style.left = offLeft + 'px'
            },
            href(id){
                this.$router.push({
                    path:`/product/detail/${id}`
                })
            }
        },
        computed:{
            dataKind(){
                let arr = []
                this.data.cate?.forEach((item) => {
                   let list = this.data.products.filter(values => {
                        return values.cate_id === item.id
                    })
                    arr.push(list)
                })
                return arr
            }
        }
    }
</script>

<style scoped lang="less">
    .select{
        width: 100%;
        min-height: 100vh;
        height: 100%;
        position: relative;
        background: url("https://khome2.tuzuu.com/vita/select_1.png"),url("https://khome2.tuzuu.com/vita/select_2.png"),url("https://khome2.tuzuu.com/vita/select_3.png");
        background-repeat: no-repeat;
        background-position: 100% 0%,0% 40px,0% 100%;
        background-size:37%,18%,33%;
        background-color: #F3F3F4;
    }
    .tab{

        position: relative;
        padding: 0px 20px;
        width: 100%;
        box-sizing: border-box;
        height: 55px;
        margin-top: 30px;
        background-color: rgba(0,0,0,0.05);
        .tab-item{
            color:#292C2F;
            flex: 1;
            text-align: center;
            font-size: 15px;
            font-weight: 700;
            transition: all 0.2s;
            letter-spacing: 0.5px;
        }
        .tab-sub{
            position: absolute;
            width: 10%;
            background: #6B00AF;
            height: 3px;
            border-radius: 50px;
            bottom: 8px;
            left: 40px;
            transition: all 0.3s;
        }
        .tab-active{
            font-weight: 900;
            font-size: 18px;
            color: #6B00AF;
        }
    }

    .tab-con{
        width: 100%;
        height: 100%;
        display: flex;
        transition: all 0.5s;
        margin-top: 40px;
        .tab-con-item{
            width: 100%;
            flex-shrink: 0;
            padding: 0px 20px;
            box-sizing: border-box;
            /deep/ .van-image{
                margin-bottom: 10px;
            }
        }
        .inactive{
            height: 0;
            overflow: visible;
        }
    }
</style>
